/** @jsx jsx */
import { Button, Container, Flex, Heading, jsx } from 'theme-ui'
import {
  useAuth,
  useTranslate,
  useUser,
  UserStatus
} from '@chordcommerce/gatsby-theme-performance'
import Metadata from '~/components/Metadata'
import AccountProfileOrderHistory from '~/components/Account/Profile/OrderHistory'
import AccountProfileDetails from '~/components/Account/Profile/Details'
import PageSpinner from '~/components/Generic/PageSpinner'

const AccountProfilePage = () => {
  const translate = useTranslate()
  const { logout } = useAuth()
  const { user, status } = useUser()

  const onLogout = async () => {
    await logout()
  }

  if (status !== UserStatus.Succeeded) {
    return <PageSpinner />
  }

  return (
    <Container>
      <Metadata title={translate('account.profile.page_title')} />
      <Flex
        sx={{
          flexDirection: 'column',
          marginBottom: ['1.5rem', null, '4.5rem'],
          marginTop: ['0', null, '42px'],
          textAlign: ['center', null, 'left'],
          padding: ['24px 16px', null, '42px 36px']
        }}
      >
        <Heading
          as="h1"
          sx={{
            variant: 'responsive.desktop',
            lineHeight: '1em',
            textAlign: 'left',
            paddingBottom: '1rem',
            marginBottom: '1rem'
          }}
        >
          {translate('account.profile.page_title')}
        </Heading>
        <Button onClick={onLogout}>
          {translate('account.profile.logout')}
        </Button>
      </Flex>
      <Flex>
        <AccountProfileOrderHistory
          orders={user.orders}
          sx={{ width: '50%' }}
        />
        <AccountProfileDetails user={user} sx={{ width: '50%' }} />
      </Flex>
    </Container>
  )
}

export default AccountProfilePage
