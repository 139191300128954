/** @jsx jsx */
import { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'
import {
  jsx,
  Box,
  Button,
  Checkbox,
  Input,
  Label,
  Text,
  Select,
  Spinner
} from 'theme-ui'

// countries.json was sourced from Shopify's Admin API (/admin/api/2021-04/countries.json)
// see https://shopify.dev/docs/admin-api/rest/reference/store-properties/country
import countries from './countries.json'

const AccountAddressesForm = ({
  address = {},
  isDefaultAddress = false,
  onSubmit,
  ...props
}) => {
  const { handleSubmit, register, watch } = useForm({
    defaultValues: { ...address, isDefaultAddress }
  })
  const translate = useTranslate()

  const watchCountry = watch('country')
  const selectedCountry = countries.find(({ name }) => name === watchCountry)
  const provinces = selectedCountry && selectedCountry.provinces

  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const onFormSubmit = async data => {
    if (isLoading) return
    const isDefaultAddress = data.isDefaultAddress
    const address = { ...data }
    delete address.isDefaultAddress

    try {
      setError(null)
      setIsLoading(true)
      await onSubmit({ address, isDefaultAddress })
    } catch (error) {
      if (error.code !== 'UNAUTHORIZED') {
        setError(error)
        setIsLoading(false)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} {...props}>
      <Label htmlFor="firstName">
        {translate('account.addresses.first_name_input_label')}
      </Label>
      <Input ref={register()} name="firstName" type="text" />

      <Label htmlFor="lastName">
        {translate('account.addresses.last_name_input_label')}
      </Label>
      <Input ref={register()} name="lastName" type="text" />

      <Label htmlFor="company">
        {translate('account.addresses.company_input_label')}
      </Label>
      <Input ref={register()} name="company" type="text" />

      <Label htmlFor="address1">
        {translate('account.addresses.address_1_input_label')}
      </Label>
      <Input ref={register()} name="address1" type="text" />

      <Label htmlFor="address2">
        {translate('account.addresses.address_2_input_label')}
      </Label>
      <Input ref={register()} name="address2" type="text" />

      <Label htmlFor="city">
        {translate('account.addresses.city_input_label')}
      </Label>
      <Input ref={register()} name="city" type="text" />

      <Label htmlFor="country">
        {translate('account.addresses.country_input_label')}
      </Label>
      <Select ref={register()} name="country">
        {countries.map(country => (
          <option key={country.code}>{country.name}</option>
        ))}
      </Select>

      <Box sx={{ display: provinces ? 'auto' : 'none' }}>
        <Label htmlFor="province">
          {translate('account.addresses.province_input_label')}
        </Label>
        <Select ref={register()} name="province">
          {provinces &&
            provinces.map(province => (
              <option key={province.code}>{province.name}</option>
            ))}
        </Select>
      </Box>

      <Label htmlFor="zip">
        {translate('account.addresses.zip_input_label')}
      </Label>
      <Input ref={register()} name="zip" type="text" />

      <Label htmlFor="phone">
        {translate('account.addresses.phone_input_label')}
      </Label>
      <Input ref={register()} name="phone" type="text" />

      <Label>
        {translate('account.addresses.default_address_checkbox_label')}
        <Checkbox ref={register()} name="isDefaultAddress" />
      </Label>

      {error && <Text variant="formError">{error.message}</Text>}

      <Button type="submit">
        {isLoading && (
          <Spinner data-testid="spinner" size="15" color="inherit" />
        )}
        {!isLoading && (
          <Text variant="link">
            {translate('account.addresses.submit_button')}
          </Text>
        )}
      </Button>
    </form>
  )
}

AccountAddressesForm.propTypes = {
  address: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    company: PropTypes.string,
    country: PropTypes.string,
    countryCodeV2: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    province: PropTypes.string,
    provinceCode: PropTypes.string,
    zip: PropTypes.string
  }),
  isDefaultAddress: PropTypes.bool,
  onSubmit: PropTypes.func
}

export default AccountAddressesForm
