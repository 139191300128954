/** @jsx jsx */
import { Fragment } from 'react'
import { jsx, Box, Heading, Grid, Text, Link } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'

const AccountProfileOrderHistory = ({ orders, ...props }) => {
  const translate = useTranslate()

  return (
    <Box {...props}>
      <Heading
        as="h2"
        sx={{
          variant: 'responsive.desktop',
          lineHeight: '1em',
          textAlign: 'left',
          paddingBottom: '1rem',
          marginBottom: '1rem'
        }}
      >
        {translate('account.profile.order_history_title')}
      </Heading>
      <Grid columns={3} gap={'3rem'}>
        <Box>
          <Text>{translate('account.profile.column_date')}</Text>
        </Box>
        <Box>
          <Text>{translate('account.profile.column_number')}</Text>
        </Box>
        <Box>
          <Text>{translate('account.profile.column_status')}</Text>
        </Box>
        {orders &&
          orders.map(order => (
            <Fragment key={order.id}>
              <Box>
                <Text>{order.processedAt}</Text>
              </Box>
              <Box>
                <Text>{order.name}</Text>
              </Box>
              <Box>
                <Text>
                  <Link href={order.statusUrl} sx={{ variant: 'text.link' }}>
                    {order.fulfillmentStatus}
                  </Link>
                </Text>
              </Box>
            </Fragment>
          ))}
      </Grid>
    </Box>
  )
}

export default AccountProfileOrderHistory
