/** @jsx jsx */
import { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { jsx, Button, Heading, Text } from 'theme-ui'
import { useTranslate, useUser } from '@chordcommerce/gatsby-theme-performance'
import AccountAddressesForm from '~/components/Account/Addresses/Form'
import AccountAddressesAddressText from '~/components/Account/Addresses/AddressText'
import ButtonAsync from '~/components/Generic/ButtonAsync'

const AccountAddressesAddress = ({ address, isDefaultAddress = false }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [error, setError] = useState()
  const { modifyUserAddress, removeUserAddress } = useUser()
  const translate = useTranslate()

  if (!address) return null

  const onDelete = async () => {
    await removeUserAddress(address.id)
  }

  const onEdit = () => {
    setIsEditing(!isEditing)
  }

  const onError = error => {
    setError(error)
  }

  const onEditSubmit = async formData => {
    await modifyUserAddress(
      address.id,
      formData.address,
      formData.isDefaultAddress
    )
    setIsEditing(false)
  }

  return (
    <Fragment>
      {isDefaultAddress && (
        <Heading as="h4">
          {translate('account.addresses.default_address_label')}
        </Heading>
      )}

      <AccountAddressesAddressText address={address} />

      <Button type="button" onClick={onEdit}>
        <Text variant="link">{translate('account.addresses.edit_button')}</Text>
      </Button>

      <ButtonAsync onClick={onDelete} onError={onError}>
        {translate('account.addresses.delete_button')}
      </ButtonAsync>

      {error && <Text variant="formError">{error.message}</Text>}

      {isEditing && (
        <AccountAddressesForm
          address={address}
          isDefaultAddress={isDefaultAddress}
          onSubmit={onEditSubmit}
        />
      )}
    </Fragment>
  )
}

AccountAddressesAddress.propTypes = {
  address: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    company: PropTypes.string,
    country: PropTypes.string,
    countryCodeV2: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.string.isRequired,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    province: PropTypes.string,
    provinceCode: PropTypes.string,
    zip: PropTypes.string
  }),
  isDefaultAddress: PropTypes.bool
}

export default AccountAddressesAddress
