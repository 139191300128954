/** @jsx jsx */
import { Link } from 'gatsby'
import { jsx, Box, Heading } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'
import AccountAddressesAddress from '~/components/Account/Addresses/Address'

const AccountProfileDetails = ({ user, ...props }) => {
  const translate = useTranslate()

  if (!user) return null

  return (
    <Box {...props}>
      <Heading
        as="h2"
        sx={{
          variant: 'responsive.desktop',
          lineHeight: '1em',
          textAlign: 'left',
          paddingBottom: '1rem',
          marginBottom: '1rem'
        }}
      >
        {translate('account.profile.account_details_title')}
      </Heading>
      {user.defaultAddress && (
        <Box>
          <AccountAddressesAddress address={user.defaultAddress} />
        </Box>
      )}

      <Link to="/account/addresses/" sx={{ variant: 'text.link' }}>
        {translate('account.profile.view_addresses_link')} (
        {user.addresses?.length || 0})
      </Link>
    </Box>
  )
}

export default AccountProfileDetails
