/** @jsx jsx */
import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { jsx, Heading, Text } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'
import AccountAddressesAddress from '~/components/Account/Addresses/Address'

const AccountAddressesList = ({ user }) => {
  const hasAddresses = !!user.addresses?.length
  const translate = useTranslate()
  return (
    <Fragment>
      <Heading as="h2">
        {translate('account.addresses.addresses_list_title')}
      </Heading>
      {!hasAddresses && (
        <Text>{translate('account.addresses.empty_list')}</Text>
      )}
      {hasAddresses &&
        user.addresses.map(address => (
          <AccountAddressesAddress
            key={address.id}
            address={address}
            isDefaultAddress={
              user.defaultAddress && user.defaultAddress.id === address.id
            }
          />
        ))}
    </Fragment>
  )
}

AccountAddressesList.propTypes = {
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      address1: PropTypes.string,
      address2: PropTypes.string,
      city: PropTypes.string,
      company: PropTypes.string,
      country: PropTypes.string,
      countryCodeV2: PropTypes.string,
      firstName: PropTypes.string,
      id: PropTypes.string.isRequired,
      lastName: PropTypes.string,
      phone: PropTypes.string,
      province: PropTypes.string,
      provinceCode: PropTypes.string,
      zip: PropTypes.string
    })
  ),
  defaultAddress: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    company: PropTypes.string,
    country: PropTypes.string,
    countryCodeV2: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.string.isRequired,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    province: PropTypes.string,
    provinceCode: PropTypes.string,
    zip: PropTypes.string
  }),
  displayName: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  id: PropTypes.string,
  lastName: PropTypes.string,
  orders: PropTypes.array,
  phone: PropTypes.string
}

export default AccountAddressesList
