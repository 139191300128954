/** @jsx jsx */
import { jsx, Button, Input } from 'theme-ui'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { navigate, Link } from 'gatsby'
import { useAuth, useTranslate } from '@chordcommerce/gatsby-theme-performance'

const AuthLoginForm = () => {
  const { register, handleSubmit } = useForm()
  const translate = useTranslate()
  const { login } = useAuth()
  const [error, setError] = useState(null)

  const onLogin = async ({ email, password }) => {
    setError(null)
    try {
      await login({ email, password })
      navigate('/account/')
    } catch (error) {
      setError(error)
    }
  }

  return (
    <form onSubmit={handleSubmit(onLogin)}>
      <label htmlFor="email">
        {translate('account.login.email_input_label')}
      </label>
      <Input ref={register()} name="email" type="email" autocomplete="email" />
      <label htmlFor="password">
        {translate('account.login.password_input_label')}
      </label>
      <Input
        ref={register()}
        name="password"
        type="password"
        autocomplete="current-password"
      />

      {error && <p className="error">{error.message}</p>}
      <Button type="submit">{translate('account.login.button')}</Button>
      <p>
        <Link to="/account/forgot/">
          {translate('account.login.forgot_link')}
        </Link>
      </p>
      <p>
        <Link to="/account/register/">
          {translate('account.login.register_link')}
        </Link>
      </p>
    </form>
  )
}
export default AuthLoginForm
