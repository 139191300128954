import React from 'react'
import { Router } from '@reach/router'
import AccountAddressesPage from '~/components/Account/Addresses/Page'
import AccountForgotPasswordPage from '~/components/Account/ForgotPassword/Page'
import AccountLoginPage from '~/components/Account/Login/Page'
import AccountProfilePage from '~/components/Account/Profile/Page'
import AccountRegisterPage from '~/components/Account/Register/Page'
import AccountResetPasswordPage from '~/components/Account/ResetPassword/Page'
import PrivateRoute from '~/components/Account/PrivateRoute'
import Layout from '~/components/Layout'

const Account = () => {
  return (
    <Layout>
      <Router basepath="/account">
        <AccountLoginPage path="/login" />
        <AccountForgotPasswordPage path="/forgot" />
        <AccountResetPasswordPage path="/reset" />
        <AccountRegisterPage path="/register" />
        <PrivateRoute path="/addresses" component={AccountAddressesPage} />
        <PrivateRoute path="/" component={AccountProfilePage} />
      </Router>
    </Layout>
  )
}

export default Account
