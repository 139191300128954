/** @jsx jsx */
import { Fragment, useEffect, useState } from 'react'
import { Box, Button, Container, Flex, Heading, jsx } from 'theme-ui'
import {
  useAuth,
  useTranslate,
  useUser
} from '@chordcommerce/gatsby-theme-performance'
import Metadata from '~/components/Metadata'
import AccountAddressesList from '~/components/Account/Addresses/List'
import AccountAddressesForm from '~/components/Account/Addresses/Form'

const AccountAddressPage = () => {
  const translate = useTranslate()
  const { getToken } = useAuth()
  const { user, loadUser, createUserAddress } = useUser()
  const [isAdding, setIsAdding] = useState(false)

  useEffect(() => {
    if (!user.id) {
      getToken().then(() => loadUser())
    }
  }, [user, getToken, loadUser])

  const onAdd = () => {
    setIsAdding(!isAdding)
  }

  const onAddSubmit = async ({ address, isDefaultAddress }) => {
    await createUserAddress(address, isDefaultAddress)
    setIsAdding(false)
  }

  return (
    <Container>
      <Metadata title={translate('account.addresses.page_title')} />
      <Flex
        sx={{
          flexDirection: 'column',
          marginBottom: ['1.5rem', null, '4.5rem'],
          marginTop: ['0', null, '42px'],
          textAlign: ['center', null, 'left'],
          padding: ['24px 16px', null, '42px 36px']
        }}
      >
        <Heading
          as="h1"
          sx={{
            variant: 'responsive.desktop',
            lineHeight: '1em',
            textAlign: 'left',
            paddingBottom: '1rem',
            marginBottom: '1rem'
          }}
        >
          {translate('account.addresses.page_title')}
        </Heading>
      </Flex>
      {user && (
        <Flex>
          <Box sx={{ width: ['100%', null, '50%'] }}>
            <Button onClick={onAdd}>
              {translate('account.addresses.add_button')}
            </Button>
          </Box>
          <Box sx={{ width: ['100%', null, '50%'] }}>
            {isAdding && (
              <Fragment>
                <Heading as="h2">
                  {translate('account.addresses.add_address_title')}
                </Heading>
                <AccountAddressesForm
                  onSubmit={onAddSubmit}
                  sx={{ paddingBottom: '1rem', marginBottom: '1rem' }}
                />
              </Fragment>
            )}
            <AccountAddressesList user={user} />
          </Box>
        </Flex>
      )}
    </Container>
  )
}

export default AccountAddressPage
